/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import Modal from 'modules/components/Modal';
import styled from 'styled-components';

const ModalBody = styled(Modal.Body)`
  overflow: auto;
  padding: 0px;
`;

export {ModalBody};
