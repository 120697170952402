/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const Container = styled.li`
  display: flex;
  align-items: center;
  font-weight: normal;
  &:first-child {
    font-weight: 500;
  }
  &:not(:first-child) > .active {
    font-weight: 600;
  }
`;

const Label = styled.span`
  margin: 0 20px;
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
`;

export {Container, Label, Link};
