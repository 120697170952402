/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. Licensed under a proprietary license.
 * See the License.txt file for more information. You may not use this file
 * except in compliance with the proprietary license.
 */

import styled from 'styled-components';

const OperationsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 37px;
`;

export {OperationsContainer};
